<template>
  <div>
    <h2 class="col-lg-12 text-center mb-3">{{ ('Add Speaker') }}</h2>
    <FormSpeakers @submit-requested="submitRequested"></FormSpeakers>
  </div>
</template>
 
<script>
import { mapActions } from 'vuex'
import FormSpeakers from '../forms/FormSpeakers.vue'

  export default {
  components: { FormSpeakers },
  methods: {
    ...mapActions('speakers', ['addSpeakers']),
    submitRequested (speaker) {
      this.addSpeakers(speaker);
    },
  }

  }
</script> 
 
<style scoped>
thead > tr > th
{
  text-align: unset !important;
}
</style> 