export default class InputSpeakers
{
  constructor(id,speak,photo) 
  {
    // TODO Validation

    if(id)
    {
      this.id = id;
    }
    
    this.speak = speak;
    this.photo = photo;
    
  }    
}